import React from 'react';
import { Typography } from '@popmenu/common-ui';
import formGroupStyles from '~/assets/jss/shared/formGroupStyles';
import { makeStyles } from '@popmenu/common-ui';
import { type FormFieldGroupProps } from './interfaces';

export const FormFieldContainer = ({ children, error = null, errorId = undefined, notice = null, title = null } : FormFieldGroupProps) => {
  const classes = makeStyles(formGroupStyles)();
  return (
    <React.Fragment>
      {children}
      {!error && notice && (
        <Typography data-cy={`${title}-field-notice`} className={classes.formGroupNoticeMessage} role="alert">
          {notice}
        </Typography>
      )}
      {error && (
        <Typography id={errorId} data-cy={`${title}-field-error`} className={classes.formGroupErrorMessage} role="alert">
          {error}
        </Typography>
      )}
    </React.Fragment>
  );
};
