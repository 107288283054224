import React from 'react';
import { TextField, Box } from '@popmenu/common-ui';
import { useField } from 'react-form';
import { validate } from '~/utils/fieldValidators';
import { FormFieldContainer } from '~/admin/shared/forms/FormFieldContainer';

export const DateTimeLocaleInput = (props: {advancedFormField: Record<string, unknown>}) => {
  const fieldName = `field_${props.advancedFormField.id as string}`;
  const field = useField<string>(fieldName, { validate: validate({ required: Boolean(props.advancedFormField.isRequired) }) });
  const { onBlur, onChange } = field.getInputProps();

  return (
    <Box mb={3} width="100%">
      <FormFieldContainer
        error={field.meta.error ? `${props.advancedFormField.placeholder as string} ${field.meta.error}` : ''}
      >
        <TextField
          fullWidth
          id={`${fieldName}-input`}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            'aria-label': props.advancedFormField.placeholder as string,
          }}
          onBlur={onBlur}
          onChange={onChange}
        />
      </FormFieldContainer>
    </Box>
  );
};
